/* src/components/ProjectTransition.css */

/* Enhanced Container */
.project-item {
  position: relative;
  width: 100%;
  height: auto; /* Allow height to adjust based on content */
  overflow: hidden;
  margin: 20px 0; /* Add vertical spacing between items */
  padding: 20px; /* Add internal padding */
  box-sizing: border-box; /* Ensure padding is included in width and height */
  background: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.project-item:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

/* src/components/ProjectTransition.css */
/* src/components/ProjectTransition.css */

/* Ensure the shader-project takes full width and centers content */
.shader-project {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Optional: Add perspective for 3D effects */
  perspective: 1000px;
}

/* Scroll Indicator Positioning and Styling */
.scroll-indicator-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
  /* Optional: Add pointer-events to ensure it's clickable */
  pointer-events: auto;
}

/* Optional: Style the ScrollIndicator component */
.scroll-indicator-wrapper svg {
  width: 30px;
  height: 30px;
  fill: #ffffff;
  /* Ensure the SVG scales smoothly */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Add hover effect to ScrollIndicator */
.scroll-indicator-wrapper:hover svg {
  fill: #ffdd57; /* Change color on hover for better visibility */
  transform: translateY(5px); /* Slight downward movement on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 600px) {
  .scroll-indicator-wrapper {
    bottom: 15px;
  }

  .scroll-indicator-wrapper svg {
    width: 25px;
    height: 25px;
  }
}
