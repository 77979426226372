/* src/components/LandingShader.css */
.landing-shader-canvas {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure it stays in the background */
    background: transparent; /* Fallback background color */
}

/* src/components/ProjectItem.css */
/* Create a new CSS file for project items or add to existing styles */

.project-item {
    position: relative;
    z-index: 1; /* Ensure project items are above the shader */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    overflow: hidden; /* To contain pseudo-elements */
    transition: transparent 0.3s ease;
}
