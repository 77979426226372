/* src/components/Navbar.css */

/* Root CSS Variables */
:root {
  --background-color: rgba(0, 0, 0, 0.8); /* Darker semi-transparent black */
  --blur-radius: 10px;
  --transition-speed: 0.3s;
  --text-color: #fff;
  --hover-color: #00f; /* Highlight color on hover and active */
}

/* Reset box-sizing for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Navbar Styling */
.navbar {
  /* Darker semi-transparent black background */
  background: var(--background-color);
  
  /* Fixed height */
  height: 60px;
  
  /* Flexbox for centering content */
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Fixed position at the top */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  
  /* Box shadow for depth */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  
  /* Blur effect */
  backdrop-filter: blur(var(--blur-radius));
  -webkit-backdrop-filter: blur(var(--blur-radius)); /* For Safari */
  
  /* Optional: Add a border for better separation */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

/* Navbar Container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  height: 60px;
  width: 100%;
  max-width: 1200px; /* Corrected with units */
  padding: 0 20px;
  margin: 0 auto; /* Center the container */
}

/* Hamburger Icon */
.menu-icon {
  display: none; /* Hidden on desktop */
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--text-color);
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

/* Navigation Links */
.nav-links {
  color: var(--text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: color var(--transition-speed) ease-in-out;
}

.nav-links:hover,
.nav-links.active {
  color: var(--hover-color); /* Highlight color on hover and active */
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block; /* Show hamburger on mobile */
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px); /* Full height minus navbar */
    position: fixed;
    top: 60px; /* Below the navbar */
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: var(--background-color); /* Darker semi-transparent background */
    backdrop-filter: blur(var(--blur-radius));
    -webkit-backdrop-filter: blur(var(--blur-radius)); /* For Safari */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 999; /* Below the navbar */
  }

  .nav-menu.active {
    left: 0;
    transition: all 0.5s ease;
  }

  .nav-menu ul {
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover,
  .nav-links.active {
    color: var(--hover-color); /* Consistent hover color */
    transition: color var(--transition-speed) ease-in-out;
  }
}

/* Enhance text visibility on mobile */
@media (max-width: 768px) {
  .navbar {
    background: var(--background-color); /* Use the same darker background */
  }

  .nav-links {
    color: var(--text-color); /* Ensure text is white for contrast */
  }
}
