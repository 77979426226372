/* src/components/ProjectPage.css */

/* Base Project Page Styles */
.project-page {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: background 0.3s ease;
    z-index: 1000; /* Ensure it's above shader */
}

/* Shader Background */
.shader-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Base layer */
    background-size: cover;
    background-position: center;
    /* Optional: Add shader-specific styles if needed */
}

/* Content Wrapper */
.content-wrapper {
    position: relative;
    z-index: 1; /* Above shader */
    cursor: pointer;
}

/* Project Content (Collapsed State) */
.project-content {
    position: relative;
    z-index: 1;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    max-width: 800px;
    margin: 20px auto;
    color: #fff; /* Ensure text is visible */
}

/* Expanded State Styles */
.project-page.expanded {
    background: rgba(0, 0, 0, 0.4); /* Reduced opacity */
}

/* Expanded Content Wrapper */
.project-page.expanded .content-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    height: 100%; /* Full height */
    padding: 0; /* Remove padding */
    box-sizing: border-box;
}

/* Adjusted .project-expanded-content */
.project-page.expanded .project-expanded-content {
    width: 60%; /* Reduced width */
    height: auto; /* Let content define height */
    max-width: 1000px; /* Optional max width */
    max-height: 90vh; /* Optional max height */
    margin: 0; /* Remove margins */
    background: rgba(0, 0, 0, 0.6); /* Reduced opacity */
    padding: 30px; /* Adjusted padding */
    border-radius: 15px; /* Larger border radius */
    overflow-y: auto; /* Enable scrolling */
    position: relative; /* To position the close button */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* Add depth */
    color: #fff; /* Ensure text is visible */
}

/* Typography for expanded content */
.project-page.expanded .project-expanded-content h1 {
    font-size: 3rem; /* Larger heading */
    margin-bottom: 20px;
}

.project-page.expanded .project-expanded-content p {
    font-size: 1.2rem; /* Larger text */
    line-height: 1.8;
}

/* Custom scrollbar for webkit browsers (Chrome, Safari, etc.) */
.project-page.expanded .project-expanded-content {
    scrollbar-width: thin;
    scrollbar-color: #888 #e0e0e0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.project-page.expanded .project-expanded-content::-webkit-scrollbar {
    width: 10px;
}

.project-page.expanded .project-expanded-content::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.project-page.expanded .project-expanded-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #e0e0e0;
}

.project-page.expanded .project-expanded-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Prevent scrolling when expanded */
.no-scroll {
    overflow: hidden;
}

/* Highlights List (Bullet Points) */
.highlights-list {
    padding-left: 20px;
    list-style-type: disc;
    margin-top: 10px;
}

.highlights-list li {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 5px;
    color: #fff;
}

/* Close Button Styles */
.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #ff5555;
}

.close-button:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
}

/* Additional Mobile Styles */
@media (max-width: 800px) {
    .project-page {
        height: 100vh;
    }

    .project-page .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .shader-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 0;
    }

    .project-page h1 {
        font-size: 2rem;
    }

    .project-page p {
        font-size: 1rem;
    }

    .button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .project-page.expanded .project-expanded-content {
        max-height: 80vh;
        overflow-y: auto;
        width: 90%; /* Ensure it doesn't cover too much */
        padding: 20px;
    }

    /* Override specific selectors for mobile */
    .project-page.expanded .project-expanded-content h1 {
        font-size: 2rem; /* Adjust heading size for mobile */
        margin-bottom: 15px;
    }

    .project-page.expanded .project-expanded-content p {
        font-size: 1rem; /* Reduce body text size */
        line-height: 1.6; /* Optional: Adjust line height */
    }

    .highlights-list li {
        font-size: 0.9rem; /* Adjust list item size */
    }

    .close-button {
        top: 15px;
        right: 15px;
        font-size: 1.8rem; /* Smaller close button */
    }

    .close-button:hover {
        color: #ff4444; /* Slight color adjustment for hover */
    }
}

/* New Styles for Larger Screens */
@media (min-width: 801px) {
    .project-page.expanded .content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; /* Full height */
        padding: 0; /* Remove padding */
    }

    .project-page.expanded .project-expanded-content {
        width: 60%; /* Reduced width for more shader visibility */
        height: auto;
        max-width: 1000px;
        max-height: 90vh;
        margin: 0;
        background: rgba(0, 0, 0, 0.6); /* Reduced opacity */
        padding: 30px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    }

    /* Typography Enhancements */
    .project-page.expanded .project-expanded-content h1 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .project-page.expanded .project-expanded-content p {
        font-size: 1.2rem;
        line-height: 1.8;
    }

    .highlights-list {
        padding-left: 30px;
        margin-top: 15px;
    }

    .highlights-list li {
        font-size: 1.1rem;
        line-height: 1.6;
    }

    /* Close Button Adjustments */
    .close-button {
        top: 30px;
        right: 30px;
        font-size: 2.5rem;
    }

    .close-button:hover {
        color: #ff7777;
    }
}

