/* ShaderBackground.css */
.shader-background {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
  }
  
  .shader-background canvas {
    width: 100% !important;
    height: 100% !important;
  }
  