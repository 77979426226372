/* src/components/LandingSection.css */

.landing-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.landing-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Canvas is below the content */
}

.landing-content {
  position: absolute; /* Position absolutely to overlay the canvas */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the content */
  z-index: 2; /* Content is above the canvas */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; /* Ensure text color contrasts with the canvas */
  text-align: center;
  pointer-events: none; /* Allows interactions with underlying elements if needed */
  /* Optional styling for better visibility */
  background: rgba(0, 0, 0, 0.0); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
}

.landing-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.landing-content p {
  font-size: 1.5rem;
}
