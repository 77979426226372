/* src/components/ScrollContainer.css */

/* Define a CSS variable for the nav bar height */
:root {
    --nav-height: 80px; /* Adjust this to your nav bar height */
}

/* Scroll Container */
.scroll-container {
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-padding-top: var(--nav-height);
    padding: 0;
    background: transparent;
    position: relative;
}

/* Projects Section */
.projects-section {
    scroll-snap-align: start;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    padding-top: var(--nav-height);
    background: transparent;
}

/* Project Grid */
.project-grid {
    display: flex;
    flex-direction: column; /* Stack projects vertically */
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0;
    padding: 0;
}

.shader-project {
    position: relative;
    width: 100%;
    min-height: calc(100vh - var(--nav-height));
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1;
    transform-style: preserve-3d;
    scroll-snap-align: start;
    flex: 0 0 auto;

    /* Flexbox properties to align content to the center */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align content vertically to the center */
    align-items: center; /* Center content horizontally */
    padding: 0; /* Remove bottom padding to fully center */
}

/* Hover Effects */
.shader-project::before,
.shader-project::after,
.shader-project:hover,
.shader-project:hover::before,
.shader-project:hover::after {
    /* Your existing styles */
}

.project-item.expanded {
    z-index: 10;
    position: fixed;
    top: var(--nav-height);
    left: 0;
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    overflow-y: auto; /* Enable scrolling if content overflows */
    background: #fff; /* Optional: Set a background color */
    transform: none;
    transition: none;

    /* Adjust flex properties to align content in the center */
    display: flex; /* Use flexbox layout */
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 0;
}

/* Hide other project items when one is expanded */
.project-item:not(.expanded) {
    display: none;
}

/* Portfolio Section */
.portfolio-section {
    scroll-snap-align: start;
    padding-top: var(--nav-height);
    background: transparent;
}

/* Contact Section */
.contact-section {
    scroll-snap-align: start;
    padding-top: var(--nav-height);
    background: transparent;
}

/* Scroll Indicator */
.scroll-indicator {
    position: fixed;
    /* Other styles as needed */
}

/* Responsive Adjustments */

/* Mobile-specific layout */
@media (max-width: 800px) {
    .scroll-container {
        scroll-snap-type: y mandatory;
        scroll-padding-top: var(--nav-height);
    }

    .project-grid {
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    .shader-project {
        flex: 1 1 auto;
        max-width: 100%;
        /* Maintain full-screen height on mobile */
        min-height: calc(100vh - var(--nav-height));
        padding-top: 0;
    }

    /* Remove display: block to maintain flex on mobile */
    .project-item.expanded {
        position: fixed;
        top: var(--nav-height);
        left: 0;
        width: 100vw;
        height: calc(100vh - var(--nav-height));
        overflow-y: auto;
        background: #fff;
        z-index: 10;
    }

    .projects-section,
    .portfolio-section,
    .contact-section {
        scroll-snap-align: start;
        padding-top: var(--nav-height);
    }
}

/* Landscape mode for mobile */
@media (max-width: 800px) and (orientation: landscape) {
    .scroll-container {
        scroll-snap-type: y mandatory;
        scroll-padding-top: var(--nav-height);
    }

    .project-grid {
        flex-direction: column;
    }
}

/* Adjust `.scroll-container` for smaller devices */
@media (max-width: 600px) {
    .scroll-container {
        scroll-snap-type: y mandatory;
        scroll-padding-top: var(--nav-height);
        padding: 0;
    }

    .transition-overlay {
        background: linear-gradient(to bottom, #1e3c72 50%, transparent 100%);
    }

    /* Typography Adjustments */
    body {
        font-family: 'Roboto', sans-serif;
        line-height: 1.6;
        font-size: 14px;
    }

    .shader-project h3 {
        font-size: 1.25rem;
    }

    .shader-project p {
        font-size: 0.9rem;
    }
}

/* Overlay for transition */
.transition-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #1e3c72 50%, transparent 100%);
    z-index: 3;
    animation: wipe 2s ease-in-out forwards;
    pointer-events: none;
    opacity: 0.9;
}

@keyframes wipe {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}