/* src/App.css */

/* Import IBM Plex Mono via CSS */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');

/* Import Monument Extended Fonts */
@font-face {
  font-family: 'Monument Extended';
  src: url('./fonts/MonumentExtended-Regular.woff2') format('woff2'),
       url('./fonts/MonumentExtended-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('./fonts/MonumentExtended-Ultrabold.woff2') format('woff2'),
       url('./fonts/MonumentExtended-Ultrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Reset Default Browser Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set HTML, Body, and Root to Full Height */
html, body, #root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* Main App Container */
.App {
  height: 100%;
  width: 100%;
}

/* Global Typography */
body {
  background-color: #000000;
  color: #ffffff;
  font-family: 'IBM Plex Mono', 'Monument Extended', monospace, sans-serif;
}

h1, h2, h3, .project-title {
  font-family: 'Monument Extended', sans-serif;
  font-weight: 800;
}

p, .project-description, .project-button {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 400;
}

/* Buttons */
.button {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-family: 'IBM Plex Mono', monospace;
  text-transform: uppercase;
  cursor: pointer;
}

.button:hover {
  background-color: #ffffff;
  color: #000000;
}

/* Add any other global styles that were previously present */
/* src/App.css */

/* Theme Variables */
.theme-dark {
  --background-color: #000000;
  --text-color: #ffffff;
  /* Other variables... */
}

.theme-light {
  --background-color: #ffffff;
  --text-color: #000000;
  /* Other variables... */
}

/* Ensure other components use these variables for consistency */

