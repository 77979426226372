/* src/components/ScrollIndicator.css */

.scroll-indicator {
    position: absolute;
    bottom: 100px; /* 20px from the bottom of the Landing section */
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 10; /* Ensure it's above other elements */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff; /* Adjust based on your theme */
  }
  
  .scroll-indicator .tooltip {
    visibility: hidden;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    bottom: 40px; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }
  
  .scroll-indicator:hover .tooltip,
  .scroll-indicator:focus .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  /* Optional: Adjust icon color on hover */
  .scroll-indicator:hover svg,
  .scroll-indicator:focus svg {
    color: #00f; /* Change to your preferred hover color */
  }
  
  /* Responsive Icon Size */
  @media (max-width: 600px) {
    .scroll-indicator svg {
      width: 24px;
      height: 24px;
    }
  
    .scroll-indicator .tooltip {
      font-size: 0.7rem;
      width: 80px;
    }
  }
  